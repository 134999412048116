import axios from 'axios'
import router from '@/router'
import { message } from 'ant-design-vue';
import { getToken } from '@/libs/auth'
const service = axios.create({
	// timeout: 15000,
	// headers: {
	// 	'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
	// }
})
service.interceptors.request.use(config => {

	let token = getToken()
	if (token != null) {
		config.headers['apiAuth'] = `${token}`
	}
	return config;
}, error => {
	return Promise.reject(error)
});


//response拦截器
service.interceptors.response.use(
	response => {
		if(response.status==200){
			if(response.data.code==1){
				return Promise.resolve(response.data.data);
			}else if(response.data.code== -14){
				message.error(response.data.msg)
				router.push('/login')
			}else{
				message.error(response.data.msg)
			}
		}else{
			message.error(response.data.msg)
		}
	},
	error => {
		console.log(error)
		return Promise.reject(error)
		// if(error.response.status == 401){
		// 	Message.error('token 验证失败')
		// 	removeCookie()
			// router.push('/login')
		// }else{
		// 	Message.error(error.response.data.err_msg)
		// }
	}
)

export default service
